'use client';

import { useState } from 'react';
import { Button } from '../Button';
import * as Styled from './styled';

export const Search = () => {
  const [input, setInput] = useState('');

  return (
    <Styled.Container>
      <input
        type="text"
        value={input}
        placeholder="Search for content..."
        onChange={(e) => setInput(e.target.value)}
      />
      <Button text="Search" key={'Search'} page="/search" slug={input} />
    </Styled.Container>
  );
};
